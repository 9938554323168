import React from "react";
import "../main.css"; // Ensure to use the same global CSS file
import workingImage1 from "../assets/qrCode.jpeg";

const BannerSection: React.FC = () => {
  return (
    <section className="banner-section">
      <div className="banner-content">
        <div className="banner-text">
          <h1>Welcome to Our Company</h1>
          <p>
            We are dedicated to delivering top-notch solutions for your
            business. Explore our services and find out how we can help you
            achieve your goals.
          </p>
        </div>
        <div className="banner-button">
          <a href="#services" className="btn-learn-more">
            Learn More
          </a>
        </div>
      </div>
      <h1>Want to make a Payment?</h1>
      <p>Scan the QR Code to make a Payment</p>
      <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
      <img src={workingImage1} style={{width: "15%"}}/>
      </div>
    </section>
  );
};

export default BannerSection;
