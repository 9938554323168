import React from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';

const PrivacyPolicy = () => {
  return (
    <>
      <Header />
      <h1 style={{ backgroundColor: "#101010", width: "full", height: "80px", textAlign: 'center', color: "white", padding: '10px' }}>Privacy Policy</h1>

      <div className="privacy-policy-container" style={{ maxWidth: '800px', margin: '0 auto', padding: '20px' }}>
        <p><em>Last Updated: March 24, 2025</em></p>

        <section>
          <h2>1. How to Delete Your Data</h2>
          <p>How to delete my data from MARQUEL WRECKING USER</p>
          <p>To delete your account and all your data from the MARQUEL WRECKING Database, please follow these steps:</p>
          <ul>
            <li>Mail <code>e_m@marquelwrecking.org</code> with the subject "DELETE MY ACCOUNT".</li>
            <li>Include your account's email, phone number, and registered name in the email so that your account can be deleted.</li>
            <li>We will notify you by mail when the account has been deleted.</li>
            <li>Your data will not be recovered once the account has been deleted.</li>
          </ul>
        </section>

        <section>
          <h2>2. Information We Collect</h2>
          <p>We may collect the following types of personal information:</p>
          <ul>
            <li>Contact Information: Name, email address, phone number, and mailing address.</li>
            <li>Service Information: Details regarding towing services provided, vehicle information, location data, and service history.</li>
            <li>Account Credentials: Username, password, and other identifiers if you register for an account.</li>
            <li>Usage Data: Information about how you interact with our services and website, including browser type, IP address, and device information.</li>
          </ul>
        </section>

        <section>
          <h2>3. How We Use Your Information</h2>
          <p>Your information is used for:</p>
          <ul>
            <li>Providing Services: To facilitate towing and related services.</li>
            <li>Communication: To notify you about service updates, respond to inquiries, and send important service-related announcements.</li>
            <li>Improvement: To enhance our services, maintain our website, and develop new offerings.</li>
            <li>Compliance: To meet legal obligations and enforce our terms and conditions.</li>
          </ul>
        </section>

        <section>
          <h2>4. Data Sharing and Disclosure</h2>
          <p>We do not sell or lease your personal information. We may share your data with:</p>
          <ul>
            <li>Service Providers: Third-party vendors who assist in delivering our services (subject to confidentiality agreements).</li>
            <li>Legal Authorities: When required by law or in response to a legal request.</li>
            <li>Business Transfers: In connection with any merger, acquisition, or sale of company assets, your information may be transferred as part of the transaction.</li>
          </ul>
        </section>

        <section>
          <h2>5. Data Security</h2>
          <p>We employ a variety of security measures to ensure the safety of your personal information, including:</p>
          <ul>
            <li>Secure storage systems.</li>
            <li>Access controls.</li>
            <li>Regular monitoring and updating of our security practices.</li>
          </ul>
          <p>However, no method of transmission or storage is 100% secure. While we strive to protect your information, we cannot guarantee its absolute security.</p>
        </section>

        <section>
          <h2>6. Data Retention</h2>
          <p>We retain your personal data only for as long as necessary to fulfill the purposes for which it was collected or as required by applicable law. Once your data is no longer needed, we will take steps to securely delete or anonymize it.</p>
        </section>

        <section>
          <h2>7. Your Rights</h2>
          <p>Depending on your location and applicable law, you may have the right to:</p>
          <ul>
            <li>Access and obtain a copy of your personal data.</li>
            <li>Correct or update inaccurate or incomplete information.</li>
            <li>Request the deletion of your personal data.</li>
            <li>Object to or restrict certain processing activities.</li>
            <li>Withdraw consent where applicable.</li>
          </ul>
        </section>

        <section>
          <h2>8. Changes to This Privacy Policy</h2>
          <p>We may update this Privacy Policy from time to time. Any changes will be posted on our website with an updated “Last Updated” date. We encourage you to review this policy periodically to stay informed about our practices.</p>
        </section>

        <section>
          <h2>9. Contact Information</h2>
          <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>
          <address>
            Marquel Wrecking<br />
            Email: <a href="mailto:e_m@marquelwrecking.org">e_m@marquelwrecking.org</a><br />
            {/* Address: [Insert Physical Address]<br />
            Phone: [Insert Phone Number] */}
          </address>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
